import { type ISbStoriesParams } from 'storyblok-js-client';

export const useStoryblokOptions = (): ISbStoriesParams  => {
    const runtimeConfig = useRuntimeConfig();
    const locale = useNuxtApp().$i18n.locale as unknown as { value: string};
    const route = useRoute();
    const isStoryblokDraft = !!route.query._storyblok;
    const isStoryblokPublished = !!route.query._storyblok_published;

    return {
        language: locale.value,
        version:
          isStoryblokDraft || (!isStoryblokPublished && runtimeConfig.public.DEV)
            ? "draft"
            : "published",
    };
};
